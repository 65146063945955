/*** Layout ***/

.mco-header {
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 18%);
}

/*** Headline ***/

.mco-container-headline-1,
.mco-container-headline-2,
.mco-container-headline-3,
.mco-container-headline-4,
.mco-container-headline-5,
.mco-container-headline-6,
.mco-container-headline-7 {
	text-align: center;
}
.mco-container-headline-1 h1,
.mco-container-headline-2 h2,
.mco-container-headline-3 h3,
.mco-container-headline-4 h4,
.mco-container-headline-5 h5,
.mco-container-headline-6 h6 {
	color: var(--mco-palette-text-primary);
}

/*** Text ***/

.mco-container-text {
	text-align: center;
}

/*** List ***/

.mco-container-list h2 {
	color: var(--mco-palette-text-primary);
	font-size: 24px;
	margin: 0 0 24px;
}

/*** Image Text ***/

.mco-container-imagetext picture {
	display: block;
}
.mco-container-imagetext h3 {
	color: var(--mco-palette-text-primary);
	font-size: 24px;
	margin: 0 0 16px;
}
.mco-container-imagetext .mco-imagetext-01__content {
	padding: 0 24px;
}
.mco-container-imagetext .mco-imagetext-01__subtitle {
	font-family: "Encode Sans", sans-serif;
	font-size: 16px;
	font-weight: 300;
	padding: 8px 24px;
}
@media all and (min-width: 600px) {
	.mco-container-imagetext .mco-imagetext-01__content {
		padding: 48px 40px;
	}
}

/*** Link List ***/

.mco-container-links .mco-references-01 h2 {
	color: var(--mco-palette-text-primary);
	font-size: 24px;
	margin: 0 0 16px;
}
.mco-container-links .mco-references-02 h2 {
	color: var(--mco-palette-text-primary);
	font-size: 24px;
}

/*** Table ***/

.mco-container-table h2 {
	color: var(--mco-palette-text-primary);
	font-size: 24px;
	margin: 0 0 16px;
}
.mco-container-table .mco-table-01__cell {
	border-bottom: 1px solid #e4e4e4;
}

/*** Form ***/

.mco-form-01 {
	box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
	padding: 24px;
}
.mco-form-01 .mco-form-01__item {
	margin-bottom: 16px;
}
.mco-form-01 label {
	color: #1a1a1a;
}
@media all and (min-width: 600px) {
	.mco-form-01 {
		padding: 32px;
	}
}

/*** Gallery ***/

.mco-gallery-02-swiper-nav__prev svg {
	transform: translate(-2px, 0);
	width: 12px !important;
}
.mco-gallery-02-swiper-nav__next svg {
	transform: translate(2px, 0);
	width: 12px !important;
}
